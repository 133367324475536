
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IOfferOption } from '@alao-frontend/core'
import OfferMainDetailsItem from '~/components/offers/OfferMainDetails/OfferMainDetailsItem.vue'

@Component({
  components: {
    OfferMainDetailsItem,
  },
})
export default class OfferMainDetails extends Vue {
  @Prop({ required: true, default: () => [], type: Array })
  readonly options!: IOfferOption[]

  getAttributes (option: IOfferOption) {
    if (option.attributes) {
      return {
        'data-benefit-key': option.attributes?.key,
        'data-value': option.attributes?.value,
      }
    }
  }
}
