
import { Component, Vue } from 'nuxt-property-decorator'
import AlaoLogoWhite from '~/assets/images/icons/alao-logo-white.svg?inline'

@Component({
  components: {
    AlaoLogoWhite,
  },
})
export default class OfferBenefitBadge extends Vue {
}
