
import { Component, Vue } from 'nuxt-property-decorator'
import IconAlaoSmile from '~/components/icons/IconAlaoSmile.vue'

@Component({
  components: {
    IconAlaoSmile,
  },
})
export default class OfferCardExclusiveLabel extends Vue {
}
