
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class OfferCardDetailsTop extends Vue {
  @Prop({
    required: false,
    default: false,
    type: Boolean,
  })
  private readonly isAlaoExclusiveOffer!: boolean
}
