import { Route } from 'vue-router'

export enum AdditionalQueryParam {
  STATE = 'state',
  PARTNER = 'partner',
}

export type AdditionalQueryParams = Partial<{
  [AdditionalQueryParam.STATE]: 'initial',
  [AdditionalQueryParam.PARTNER]: string,
}>

export const plainObjectToRouteQueryObject = (payload: Record<string, string | number | boolean>): Route['query'] => {
  return Object.keys(payload).reduce((acc, key) => {
    if (['boolean', 'number'].includes(typeof payload[key])) {
      return {
        ...acc,
        [key]: String(payload[key]),
      }
    }

    return { ...acc, [key]: payload[key] }
  }, {})
}
